import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetUncorrectedSubmissionsRequest } from 'edu-application/dist/assignments/get-uncorrected-submissions/get-uncorrected-submissions.request'
import { UncorrectedAssignmentViewItem, UncorrectedSubmissionViewItem } from 'edu-application/dist/assignments/get-uncorrected-submissions/get-uncorrected-submissions.viewmodel'
import { GetAdminDashboardViewModel } from 'edu-application/dist/dashboard/get-admin-dashboard-data/get-admin-dashboard-data.viewmodel';
import { GetSupervisorAssignmentViewItem } from 'edu-application/dist/dashboard/get-supervisor-assignments/get-supervisor-assignments.viewmodel';
import { GetUnreviewedSubmissionsViewmodel } from 'edu-application/dist/assignments/get-unreviewed-submissions/get-unreviewed-viewmodel';
import { GetUnReviewedSubmissionsRequest } from 'edu-application/dist/assignments/get-unreviewed-submissions/get-unreviewed-submissions.request';
import { GetStudentTrophiesViewmodel } from 'edu-application/dist/trophies/get-student-trophies/get-student-trophies.viewmodel'
import { GetAssistantDashboardViewModel } from 'edu-application/dist/dashboard/get-assistant-dashboard-data/get-assistant-dashboard-data.viewmodel';
import { ReviewedAssignmentViewItem } from 'edu-application/dist/assignments/get-reviewed-submissions-by-corrector/get-reviewed-submissions-by-corrector.viewmodel';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';
import { GetQualityDashboardDataViewmodel } from 'edu-application/dist/dashboard/get-quality-dashboard-data/get-quality-dashboard-data.viewmodel';
import { GetGroupMaterialsViewModel } from 'edu-application/dist/groups/get-group-materials/get-group-materials.viewmodel';
import { GetGroupViewItem } from 'edu-application/dist/groups/get-groups/get-group.viewmodel';
import { EditFileGroupsRequest, EditVideoGroupsRequest, GetVideosViewItem, ResourceFilesViewModel } from 'edu-application/dist';
import { GetTeamMemberViewItem } from 'edu-application/dist/team/base/get-team-member.viewmodel';
import { ManuallyDistributeRequest } from 'edu-application/dist/distribution/manually-distribute-submissions/manually-distribute-submissions.request';
import { ManuallyDistributeResult, ManuallyDistributeResultItem } from 'edu-application/dist/distribution/manually-distribute-submissions/manually-distribute-submissions.viewmodel';
import { ManuallyDistribute } from 'edu-application/dist/distribution/manually-distribute-submissions/manually-distribute-submissions.usecase';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private client: HttpClient,
    private loginService: LoginService
  ) { }

  private convertToHttpParams(request: GetUncorrectedSubmissionsRequest): HttpParams {
    let params = new HttpParams();

    // Required parameter
    params = params.set('teamMemberId', request.teamMemberId);

    // Optional `date` object
    if (request.date) {
      if (request.date.before) {
        params = params.set('date.before', request.date.before.toISOString());
      }
      if (request.date.after) {
        params = params.set('date.after', request.date.after.toISOString());
      }
    }

    // Required `response` parameter
    params = params.set('response', request.response);

    // Optional boolean parameters
    if (request.unreviewed !== undefined) {
      params = params.set('unreviewed', String(request.unreviewed));
    }
    if (request.undistributed !== undefined) {
      params = params.set('undistributed', String(request.undistributed));
    }

    // Optional `groupIds` array
    if (request.groupIds && request.groupIds.length > 0) {
      for (let id of request.groupIds) {
        params = params.append('groupIds', id);
      }
    }
    // Optional `groupIds` array
    if (request.assignmentIds && request.assignmentIds.length > 0) {
      for (let id of request.assignmentIds) {
        params = params.append('assignmentIds', id);
      }
    }
    return params;
  }


  getUncorrectedSubmissions(userName?: string, before?: Date, after?: Date, response: "flat" | "groups" = "groups", unreviewed: boolean = false, undistributed: boolean = false, groups?: string[], asssignments?: string[]): Promise<UncorrectedAssignmentViewItem[]> {
    let request: GetUncorrectedSubmissionsRequest = {
      teamMemberId: userName ?? this.loginService.userInfo.userName,
      date: {
        before: before,
        after: after
      },
      response: response,
      unreviewed: unreviewed,
      undistributed: undistributed,
      groupIds: groups,
      assignmentIds: asssignments,
    }
    let params = this.convertToHttpParams(request);
    if (before && after) {
      return this.client.get<UncorrectedAssignmentViewItem[]>(`${environment.backend_url}/submissions/uncorrected`, { params }).toPromise()
    }
    return this.client.get<UncorrectedAssignmentViewItem[]>(`${environment.backend_url}/submissions/uncorrected`, { params }).toPromise()
  }

  getAdminDashboard(userName?: string): Promise<GetAdminDashboardViewModel> {
    let param = userName ?? this.loginService.userInfo.userName;
    return this.client.get<GetAdminDashboardViewModel>(`${environment.backend_url}/team-members/dashboard/${param}`,).toPromise()
  }

  getSupervisorsAssignments(userName: string): Promise<GetSupervisorAssignmentViewItem[]> {
    return this.client.get<GetSupervisorAssignmentViewItem[]>(`${environment.backend_url}/assignments/supervisors?userName=${userName}`).toPromise();
  }

  getUnreviewedSubmissions(group?: string, userName?: string): Promise<GetUnreviewedSubmissionsViewmodel> {
    let request: GetUnReviewedSubmissionsRequest = {
      teamMemberId: userName ?? this.loginService.userInfo.userName,
      groupId: group
    }
    return this.client.get<GetUnreviewedSubmissionsViewmodel>(`${environment.backend_url}/submissions/unreviewed/${request.teamMemberId}/${request.groupId}`,).toPromise()
  }

  getAssistantDashboard(userName?: string, startDate?: string, endDate?: string): Promise<GetAssistantDashboardViewModel> {
    let param = userName ?? this.loginService.userInfo.userName;
    return this.client.get<GetAssistantDashboardViewModel>(`${environment.backend_url}/team-members/assistant/metrics/${param}/${startDate}/${endDate}`,).toPromise();
  }

  getAssistantUncorrectedSubmissions(userName: string) {
    return this.client.get<UncorrectedAssignmentViewItem[]>(`${environment.backend_url}/submissions/assigned/${userName}`).toPromise()
  }

  getReviews(userName?: string, startDate?: string, endDate?: string) {
    let param = userName ?? this.loginService.userInfo.userName;
    return this.client.get<ReviewedAssignmentViewItem[]>(`${environment.backend_url}/submissions/reviewed/${param}/${startDate}/${endDate}`,).toPromise();
  }

  getQualityDashboard(userName?: string): Promise<GetQualityDashboardDataViewmodel> {
    let param = userName ?? this.loginService.userInfo.userName;
    return this.client.get<GetQualityDashboardDataViewmodel>(`${environment.backend_url}/team-members/quality/metrics/${param}`).toPromise();
  }

  getStudentTrophies(userName: string): Promise<GetStudentTrophiesViewmodel[]> {
    return this.client.get<GetStudentTrophiesViewmodel[]>(`${environment.backend_url}/trophies/students/${userName}`).toPromise();
  }

  async getGroupDetails(groupId: string): Promise<GetGroupMaterialsViewModel> {
    return await this.client.get<GetGroupMaterialsViewModel>(`${environment.backend_url}/groups/materials/${groupId}`).toPromise();
  }
  async getAllGroups(): Promise<GetGroupViewItem[]> {
    return await this.client.get<GetGroupViewItem[]>(`${environment.backend_url}/groups`).toPromise();
  }

  async getCorrectors(): Promise<GetTeamMemberViewItem[]> {
    return await this.client.get<GetTeamMemberViewItem[]>(`${environment.backend_url}/team-members`).toPromise();
  }

  async redistributeCorrector(correctorId: string) {
    return await this.client.post<any>(`${environment.backend_url}/submissions/redistribute/${correctorId}`, {}).toPromise();
  }

  async redistributeSubmission(submissionId: string, corrector?: string, quality?: string) {
    let request = {
      submissionId: submissionId,
      assistants: corrector != undefined ? [corrector] : undefined
    }
    return await this.client.post<any>(`${environment.backend_url}/submissions/distribute/${submissionId}`, request).toPromise();
  }

  async editFileGroups(fileId: string, groupId: string[], type: "add" | "remove"): Promise<ResourceFilesViewModel> {
    let request: EditFileGroupsRequest = {
      fileId: fileId,
      groupIds: groupId,
      type: type
    }
    let result: any = await this.client.post(`${environment.backend_url}/files/groups`, request, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }).toPromise();
    return result;
  }

  async editVideoGroups(videoId: string, groupId: string, type: "add" | "remove"): Promise<GetVideosViewItem> {
    let request: EditVideoGroupsRequest = {
      videoId: videoId,
      groupId: groupId,
      type: type
    }
    let result: any = await this.client.post(`${environment.backend_url}/videos/groups`, request, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }).toPromise();
    return result;
  }

  async distributeManually(req: ManuallyDistributeRequest): Promise<ManuallyDistributeResult> {
    return await this.client.post<ManuallyDistributeResult>(`${environment.backend_url}/submissions/manually/distribute`, req).toPromise();
  }

  async assignManuallySubmission(req: ManuallyDistributeResultItem): Promise<void> {
    return await this.client.post<void>(`${environment.backend_url}/submissions/manually/assign`, req).toPromise();
  }

  async getOverdueSubmission(type: "correction" | "review", overdue: boolean = false): Promise<UncorrectedSubmissionViewItem[]> {
    return await this.client.get<UncorrectedSubmissionViewItem[]>(`${environment.backend_url}/submissions/overdue?type=${type}&overdue=${overdue}`).toPromise();
  }
}
