import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {
  image:string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      image: string
    },
    private _dialogRef: MatDialogRef<ImageViewerComponent>,
    private router: Router,
  ) {
    this.image = this.data.image;
   }

  ngOnInit(): void {
  }

}
