import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneNumberComponent } from './phone-number/phone-number.component';
import { MatIconModule } from '@angular/material/icon';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SafePipe } from './pipe';
import { ImageButtonComponent } from './image-button/image-button.component';
import { MarkdownViewerComponent } from './markdown-viewer/markdown-viewer.component';
import { GroupsService } from './groups.service';
import { FileUploaderComponent } from './file-uploader/file-uploader.component'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { NoAssignmentsComponent } from './no-assignments/no-assignments.component';
import { StudentSelectorComponent } from './student-selector/student-selector.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
//import { RecorderComponent } from './recorder/recorder.component';
import { VoiceNotePlayerComponent } from './voice-note-player/voice-note-player.component';
import { MoneyCardComponent } from './money-card/money-card.component';
import { StudentDetailsCardComponent } from './student-details-card/student-details-card.component';
import { SimpleFileUploaderComponent } from './simple-file-uploader/simple-file-uploader.component';
import { StudentCardComponent } from './student-card/student-card.component';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { DeadlineCellComponent } from './deadline-cell/deadline-cell.component';
import { SubmissionStateComponent } from './submission-state/submission-state.component';
import { DetailItemComponent } from './detail-item/detail-item.component';
import { LogoComponent } from './logo/logo.component';
import { HeaderTitleComponent } from './header-title/header-title.component';
import { CardComponent, EduCardSubtitle, EduCardTitle } from './card/card.component';
import { PointsSummaryComponent } from './points-summary/points-summary.component';
import { EduRowComponent } from './edu-row/edu-row.component';
import { ActionButtonComponent } from './action-button/action_button.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { ProfileAvatarComponent } from './profile-avatar/profile-avatar.component'
import { AdminTimingsComponent } from './admin-timings/admin-timings.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { StudentScheduleComponent } from './student-schedule/student-schedule.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { BlockCopyDirective } from './block-copy.directive';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input'

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#f5f5f5'
    },
    button: {
      background: '#008800'
    }
  },
  theme: 'edgeless',
  type: 'opt-in'
};



@NgModule({
  declarations: [
    MoneyCardComponent,
    SafePipe,
    StudentCardComponent,
    PhoneNumberComponent,
    ImageButtonComponent,
    LoadingSpinnerComponent,
    MarkdownViewerComponent,
    FileUploaderComponent,
    NoAssignmentsComponent,
    StudentSelectorComponent,
    ImageViewerComponent,
    //RecorderComponent,
    VoiceNotePlayerComponent,
    StudentDetailsCardComponent,
    SimpleFileUploaderComponent,
    CookieConsentComponent,
    DeadlineCellComponent,
    SubmissionStateComponent,
    DetailItemComponent,
    LogoComponent,
    HeaderTitleComponent,
    CardComponent,
    EduCardTitle,
    EduCardSubtitle,
    PointsSummaryComponent,
    EduRowComponent,
    ActionButtonComponent,
    PaginatorComponent,
    ProfileAvatarComponent,
    AdminTimingsComponent,
    StudentScheduleComponent,
    BlockCopyDirective
  ],
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    MatAutocompleteModule,
    CommonModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatProgressBarModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgxMatIntlTelInputComponent
  ],
  exports: [
    PhoneNumberComponent,
    LoadingSpinnerComponent,
    SafePipe,
    ImageButtonComponent,
    MarkdownViewerComponent,
    FileUploaderComponent,
    NoAssignmentsComponent,
    StudentSelectorComponent,
    //RecorderComponent,
    VoiceNotePlayerComponent,
    MoneyCardComponent,
    StudentDetailsCardComponent,
    SimpleFileUploaderComponent,
    StudentCardComponent,
    CookieConsentComponent,
    DeadlineCellComponent,
    SubmissionStateComponent,
    DetailItemComponent,
    LogoComponent,
    HeaderTitleComponent,
    CardComponent,
    EduCardTitle,
    EduCardSubtitle,
    PointsSummaryComponent,
    EduRowComponent,
    ActionButtonComponent,
    PaginatorComponent,
    ProfileAvatarComponent,
    AdminTimingsComponent,
    BlockCopyDirective,
    NgxMatIntlTelInputComponent
  ],
  providers: []
})
export class SharedModule { }
