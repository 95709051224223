import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';

import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { QrCodeModule } from 'ng-qrcode';
import { LoginRoutingModule } from './login-routing.module';
import { CookieService } from 'ngx-cookie-service';
import { SharedModule } from '../shared/shared.module';
import { FormLoginComponent } from './form-login/form-login.component';
import { QrLoginComponent } from './qr-login/qr-login.component';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

const config: SocketIoConfig = { url: environment.backend_socket, options: { autoConnect: false, reconnection: false } };

@NgModule({
  declarations: [
    LoginComponent,
    FormLoginComponent,
    QrLoginComponent
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    QrCodeModule,
    LoginRoutingModule,
    SharedModule,
    SocketIoModule.forRoot(config)
  ],
  providers: [CookieService]
})
export class LoginModule { }
