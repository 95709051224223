import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register/register.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete'
import { QrCodeModule } from 'ng-qrcode';
import { RIVE_FOLDER, RiveModule } from 'ng-rive'
//import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input'
import { RouterModule } from '@angular/router';
import { ViewRegistrantsComponent } from './view-registrants/view-registrants.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table'
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { RegistrantCardComponent } from './registrant-card/registrant-card.component';
import { SharedModule } from '../shared/shared.module';
import { EditRegistrantComponent } from './edit-registrant/edit-registrant.component';
import { MatLegacyDialogModule as MatDialogModule, MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';
import { AcceptRegistrantComponent } from './accept-registrant/accept-registrant.component';
import { MatSortModule } from '@angular/material/sort';
import { PlaceInfoComponent } from './place-info/place-info.component';
import { WhatsappLinkComponent } from './whatsapp-link/whatsapp-link.component';
@NgModule({
  declarations: [
    RegisterComponent,
    ViewRegistrantsComponent,
    RegistrantCardComponent,
    EditRegistrantComponent,
    AcceptRegistrantComponent,
    PlaceInfoComponent,
    WhatsappLinkComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatSelectModule,
    MatSortModule,
    MatAutocompleteModule,
    RiveModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    SharedModule,
    QrCodeModule,
    RouterModule.forChild([
      {
        path: '',
        component: RegisterComponent
      }
    ])
  ],
  providers: [
    { provide: RIVE_FOLDER, useValue: 'assets/animations' },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }
  ]
})
export class RegistrationModule { }
