import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClipboardServiceService {
  private readonly clipboardKey: string = "CLIPBOARD_KEY"
  constructor(private _cookieService: CookieService) { }

  public getClipboardData() {
    // Get the cookie data using the domain from enivronment.domain
    let data = this._cookieService.get(this.clipboardKey);
    if (data) {
      return data;
    } else {
      return "";
    }
  }

  public setClipboardData(data: string) {
    this._cookieService.set(this.clipboardKey, data, { domain: environment.domain, path: '/' });
  }
}
