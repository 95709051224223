import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { GetRegistrantsViewItem } from 'edu-application/dist/registration/view-registrants/view-registrants.viewmodel';
import { RegistrationService } from '../registration.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { AcceptRegistrantRequest } from 'edu-application/dist/registration/accept-registrant/accept-registrant.request';
import { GetGroupViewItem } from 'edu-application/dist/groups/get-groups/get-group.viewmodel';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EditRegistrantComponent } from '../edit-registrant/edit-registrant.component';
import { GetPlacesViewItem } from 'edu-application/dist';
import { BehaviorSubject } from 'rxjs';
import { AcceptRegistrantComponent } from '../accept-registrant/accept-registrant.component';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { ContactsService } from 'src/app/shared/contacts.service';
import { LoginService } from 'src/app/login/login.service';
@Component({
  selector: 'app-view-registrants',
  templateUrl: './view-registrants.component.html',
  styleUrls: ['./view-registrants.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ViewRegistrantsComponent implements OnInit {
  registrants: GetRegistrantsViewItem[] = null;
  isLoading: boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<GetRegistrantsViewItem>;
  columnsToDisplay = ['fullName', "session", 'place', "state"]
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: GetRegistrantsViewItem | null;
  selectedColumn = 'name';
  allGroups: GetGroupViewItem[];
  editedRegistrantSubject: BehaviorSubject<GetRegistrantsViewItem> = new BehaviorSubject(null);
  constructor(
    private _service: RegistrationService,
    public dialog: MatDialog,
    private contactsService: ContactsService,
    private _loginService: LoginService
  ) { }

  exportContacts() {
    var items = this.dataSource.filteredData;
    let vcf = this.contactsService.getRegistrantVCF(items);
    const blob =
      new Blob([vcf],
        { type: "text/vcf" });
    saveAs(blob, `${environment.org} ${new Date().toDateString()} Students Contacts - ${this.dataSource.filter.length != 0 ? this.dataSource.filter : 'all'}.vcf`);
  }

  ngOnInit(): void {
    this.getRegistrant();
    this.getGroups();
    this.getPlaces();
    this.editedRegistrantSubject.subscribe((reg) => {
      if (reg == null) {
        return;
      }
      let oldReg = this.dataSource.data.findIndex(r => r.id == reg.id);
      this.dataSource.data[oldReg] = reg;
      this.dataSource._updateChangeSubscription();
    })
  }

  async getRegistrant() {
    this.isLoading = true;
    try {
      let regs = await this._service.getAllRegistrant();
      this.registrants = regs;
      this.dataSource = new MatTableDataSource(this.registrants);

    } catch (e) {

    } finally {
      this.isLoading = true;
    }
  }

  async getGroups() {
    this._service.getGroups().then(s => {
      this.allGroups = s;
    }).catch(e => {
    })
  }
  places: GetPlacesViewItem[];
  async getPlaces() {
    this._service.getPlaces().then(s => {
      this.places = s;
      if (this._loginService.userInfo.userName === "OS001") {
        this.places = this.places.filter(p => p.availableForRegistration);
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  paginator: MatPaginator;
  @ViewChild('paginator', { static: false }) set paginatorItem(content: MatPaginator) {
    if (content) {
      this.paginator = content;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }
  // changeSortedColumn() {
  //   const sortState: Sort = { active: this.selectedColumn, direction: 'asc' };
  //   this.sort.active = sortState.active;
  //   this.sort.direction = sortState.direction;
  //   this.sort.sortChange.emit(sortState);
  // }

  getKeys(): string[] {
    return Object.keys(this.registrants[0])
  }

  openAcceptRegistrant(registrant: GetRegistrantsViewItem) {
    this.dialog.open(AcceptRegistrantComponent, {
      data: {
        reg: registrant,
        groups: this.allGroups,
        subject: this.editedRegistrantSubject
      }
    })
  }

  filterGroups(placename: string) {
    return this.allGroups.filter(g => placename.toLowerCase().includes(g.placeName.toLowerCase()));
  }

  openEditDialog(registrant: GetRegistrantsViewItem) {
    this.dialog.open(EditRegistrantComponent, { data: { reg: registrant, places: this.places, subject: this.editedRegistrantSubject } });
  }

  deleteRegistrant(registrant: GetRegistrantsViewItem){
    if (this._loginService.userInfo.teamMemberInfo.type != 'head'){
      alert("You are not allowed to delete a registrant");
    }
    else {
      if (confirm(`Are you sure you want to delete ${registrant.fullName}?`)) {
        this._service.deleteRegistrant(registrant.id).then(s => {
          // Remove registrant from list
          let oldReg = this.dataSource.data.findIndex(r => r.id == registrant.id);
          this.dataSource.data.splice(oldReg,1);
          this.dataSource._updateChangeSubscription();
        }).catch(e => {
          alert("Error deleting registrant");
        })
      }
    }
  }

  saveRegistrants() {
    var json = this.dataSource.filteredData;
    var fields = Object.keys(json[0])
    var replacer = function (key, value) { return value === null ? '' : value }
    var csv = json.map(function (row) {
      return fields.map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer)
      }).join(',')
    })
    csv.unshift(fields.join(',')) // add header column
    let value = csv.join('\r\n');
    const blob =
      new Blob([value],
        { type: "text/csv" });
    saveAs(blob, `${environment.org} ${new Date().toDateString()} Registrants - ${this.dataSource.filter.length != 0 ? this.dataSource.filter : 'all'}.csv`);
  }
}
