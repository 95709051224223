import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IcteamGuard  {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    return this.can();
  }

  constructor(
    private _router: Router
  ) {
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.can();
  }
  private can() {
    let allowed = environment.org == "ICTeam";
    if (allowed) {
      return true;
    }
    this._router.navigate(['login']);
    return false;
  }
}
