import { Directive, HostListener } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { environment } from 'src/environments/environment';
import { ClipboardServiceService } from './clipboard-service.service';

@Directive({
  selector: '[eduBlockCopy]'
})
export class BlockCopyDirective {

  constructor(
    private snackBar: MatSnackBar,
    private _clipboardService: ClipboardServiceService
  ) { }
  private readonly signature: string = `\n\n-- Signature: Copied from Translator ${environment.org}`
  @HostListener('paste', ['$event']) blockPaste(e: ClipboardEvent) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
    if (environment.org === 'ICTeam') {
      return;
    }
    let data = this._clipboardService.getClipboardData();
    if (data === "") {
      this.snackBar.open('You are not allowed to paste', undefined, { duration: 1 * 3600 })
    } else {
      (e.target as any).value = data;
    }
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
    this.snackBar.open('You are not allowed to copy', undefined, { duration: 1 * 3600 })
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
    this.snackBar.open('You are not allowed to cut', undefined, { duration: 1 * 3600 })
  }

}
