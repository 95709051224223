import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ProfileButtonComponent } from './profile-button/profile-button.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { RamadanHeaderComponent } from './ramadan-header/ramadan-header.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavbarItemComponent } from './navbar-item/navbar-item.component';
import { EduNavbarComponent } from './edu-navbar/edu-navbar.component';
import { SharedModule } from '../shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';


@NgModule({
  declarations: [
    NavbarComponent,
    ProfileButtonComponent,
    RamadanHeaderComponent,
    NavbarItemComponent,
    EduNavbarComponent
  ],
  imports: [
    SharedModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CommonModule,
    BrowserModule,
    MatSnackBarModule,
    MatMenuModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    MatSidenavModule,
    RouterModule
  ]
})
export class NavbarModule { }
