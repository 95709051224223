import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GetGroupViewItem } from 'edu-application/dist/groups/get-groups/get-group.viewmodel';
import { ViewStudentsViewItem } from 'edu-application/dist/students/view-students/view-students.viewmodel';
import { BehaviorSubject } from 'rxjs';
import { StudentsService } from '../students.service';

@Component({
  selector: 'app-transfer-student',
  templateUrl: './transfer-student.component.html',
  styleUrls: ['./transfer-student.component.scss']
})
export class TransferStudentComponent implements OnInit {
  groupControl: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { groups: GetGroupViewItem[], student: ViewStudentsViewItem, suspensionSubject: BehaviorSubject<ViewStudentsViewItem> },
    private dialogRef : MatDialogRef<TransferStudentComponent>,
    private service: StudentsService
  ) { }

  ngOnInit(): void {
    this.data.groups = this.data.groups.filter(s => s.id !== this.data.student.group.id);
  }
  isLoading: boolean = false;
  async transfer() {
    this.isLoading = true;
    try {
      let transfer = await this.service.transferStudent(this.data.student.userName, this.groupControl.value);
      this.data.suspensionSubject.next({
        ...this.data.student,
        group: {
          id: transfer.groupId,
          name: transfer.groupName
        }
      })
      this.dialogRef.close();
    } catch (e) {

    } finally {
      this.isLoading = false;
    }
  }
}
