import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginService } from './login/login.service';
import { IcteamPrankComponent, PrankInterface } from './prank/icteam-prank/icteam-prank.component';
declare let gtag: Function;
declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  dialogRef: MatDialogRef<IcteamPrankComponent>;
  favIcon: HTMLLinkElement = document.querySelector('#appFavicon');
  constructor(private titleService: Title, private router: Router, private dialog: MatDialog, private loginService: LoginService) {
    // this.titleService.setTitle(environment.org);
    if (this.favIcon) {
      this.favIcon.href = environment.assets.favIcon;
    }
    router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        // gtag('config', 'UA-{ID}', { 'page_path': y.url });
        fbq('track', 'PageView');
      }
    })
  }

}
