import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
export interface PrankInterface {
  video: string;
}
@Component({
  selector: 'app-icteam-prank',
  templateUrl: './icteam-prank.component.html',
  styleUrls: ['./icteam-prank.component.scss']
})
export class IcteamPrankComponent implements OnInit {
  video: string;
  constructor(@Inject(MAT_DIALOG_DATA) private data: PrankInterface, private dialogRef : MatDialogRef<IcteamPrankComponent>) { this.video = this.data.video; }

  ngOnInit(): void {
    let video = document.getElementById('video-player-parnk') as HTMLVideoElement;

    video.oncanplay = () => {
      video.play();
    }
    video.onended = () => {
      this.dialogRef.close();
    }
  }

}
