import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';
import { CompilerColorBloc } from '../translator/compiler-color.bloc';
import { ThemisRoute } from './edumono-route';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  sideNavOpen: boolean = false;
  constructor(
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _loginService: LoginService
  ) {
    this._router.events.subscribe(s => {
      if (!this._router.url.includes('code')) {
        this.colorBloc.darkModeStream.next(false);
      }
      this.sideNavOpen = false;
    })
  }

  toggleSideNav(state?: boolean) {
    if (state == undefined) {
      this.sideNavOpen = !this.sideNavOpen;
      return
    }
    this.sideNavOpen = state;
  }
  isAdmin: boolean = false;
  link: string = null;
  routes = [];

  logoImage = environment.assets.logo;
  isDark: boolean = false;
  isHacker: boolean = false;
  colorBloc: CompilerColorBloc = CompilerColorBloc.CreateBloc();
  ngOnInit(): void {
    this.isAdmin = this._loginService.isAdmin
    if (this.isAdmin) {
      this.routes = [
        new ThemisRoute('Dashboard', true, 'assets/navbar/layout-fluid.svg'),
        new ThemisRoute('Videos', false, 'assets/navbar/videos.svg'),
        new ThemisRoute('Materials', false, 'assets/navbar/folder-open.svg'),
        new ThemisRoute('Registrants', true, 'assets/navbar/pending.svg'),
        new ThemisRoute('Students', true, 'assets/navbar/users-alt.svg'),
        new ThemisRoute('Calls', true, 'assets/navbar/phone-office.svg'),
        new ThemisRoute('Submissions', true, 'assets/navbar/submissions.svg'),
        new ThemisRoute('Meetings', true, 'assets/navbar/link-horizontal.svg'),
        new ThemisRoute('Lectures', true, 'assets/navbar/calendar-lines.svg'),
        new ThemisRoute('Reports', true, 'assets/navbar/stats.svg')
        // new ThemisRoute('Questions', true)
      ];
    } else {
      this.routes = [
        new ThemisRoute('Dashboard', false, 'assets/navbar/layout-fluid.svg'),
        new ThemisRoute('Home', false, 'assets/navbar/home.svg'),
        new ThemisRoute('Videos', false, 'assets/navbar/videos.svg'),
        new ThemisRoute('Materials', false, 'assets/navbar/folder-open.svg'),
        new ThemisRoute('Submissions', false, 'assets/navbar/submissions.svg')
        // new ThemisRoute('Questions', false)
        // new ThemisRoute('Pseudocode', false)
      ];

    }
    if (environment.org == "CSTeam") {
      this.routes.push(new ThemisRoute('Pseudocode', false, 'assets/navbar/laptop-code.svg'))
    } else if (environment.org == "ICTeam" && this.isAdmin && this._loginService.userInfo.teamMemberInfo.type !== 'assistant') {
      this.routes.push(new ThemisRoute('Mock Exam', true))
    }
    if (environment.org == "ICTeam" && (this._loginService.userInfo.type == 'student' && (this._loginService.userInfo.userName.includes('AS') || this._loginService.userInfo.userName.includes('ANS')) || this._loginService.userInfo.type == 'team' && this._loginService.userInfo.userName == 'N22-HD021')) {
      this.routes.push(new ThemisRoute('Pseudocode', false, 'assets/navbar/laptop-code.svg'))
    }
    if (this._loginService.userInfo.scope.includes('head')) {
      this.routes.push(new ThemisRoute('Session-Management', true, 'assets/navbar/session-management-icon.svg'))
      this.routes.push(new ThemisRoute('Payments', true, 'assets/navbar/money-bill-wave.svg'))
      this.routes.push(new ThemisRoute('Team', true, 'assets/navbar/building.svg'))
      this.routes.push(new ThemisRoute('Leaderboard', true, 'assets/navbar/ranking-star.svg'))
      this.routes.push(new ThemisRoute('Milestones', true, 'assets/navbar/flag.svg'))
    }
    if (this._loginService.userInfo.type == 'team' && this._loginService.userInfo.teamMemberInfo.type == 'supervisor') {
      this.routes.push(new ThemisRoute('Team', true, 'assets/navbar/building.svg'))
      this.routes.push(new ThemisRoute('Milestones', true, 'assets/navbar/flag.svg'))
    }
    this.colorBloc.darkModeStream.subscribe(s => {
      this.isDark = s;
    })
    this.colorBloc.hackerModeStream.subscribe(s => {
      this.isHacker = s;
    })
  }

  goToLink() {
    if (this.link) {
      window.open(this.link, '_coursework');
    } else {
      this._snackBar.open('Coursework is still loading try again in a second.', 'Dismiss', {
        duration: 3 * 3600,
        horizontalPosition: 'end',
        verticalPosition: 'top'
      })
    }
  }

}

